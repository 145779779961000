import styled from "styled-components"

const HamburgerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;

  span {
    position: relative;
    width: 100%;
    height: 3px;
    background: white;
    transform: ${({ isOpen }) => (!isOpen ? "rotate(-45deg)" : "rotate(0)")};
    transition: 0.3s;

    ::before,
    ::after {
      position: absolute;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;

      background: white;
      transform: ${({ isOpen }) => (!isOpen ? "rotate(90deg)" : "rotate(0)")};
      transition: 0.3s;
    }
    ::before {
      top: ${({ isOpen }) => (!isOpen ? "0px" : "-7px")};
    }
    ::after {
      top: ${({ isOpen }) => (!isOpen ? "0px" : "7px")};
    }
  }
`

export const MenuHamburger = ({ onClick, menuIsOpen = false }) => {
  return (
    <HamburgerContainer isOpen={menuIsOpen} onClick={onClick}>
      <span></span>
    </HamburgerContainer>
  )
}
