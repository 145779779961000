import styled, { ThemeContext} from 'styled-components';
import { BsTelephoneFill } from 'react-icons/bs';
import { useContext } from 'react';
import CommonButton from './CommonButton';

const Button = styled(CommonButton)`
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: start;
    span {
        color: #000;
        text-transform: uppercase;
    }
    svg{
        margin-right: 5px;
        font-size: 15px;
    }
    @media screen (max-width: 550px){
        justify-content: center;
    }
    ${({ full }) => {
        if(full === true) return 'height: 100%;'
    }}
` 

const PhoneBlock = ({ tel, full }) => {
    const theme = useContext(ThemeContext)
    return  <Button href={`tel:+55${tel}`} full={full}>
                    <BsTelephoneFill fill={theme.mainColor} size={30}/>
                    <span>Telefone</span>
            </Button>;
};
export default PhoneBlock;