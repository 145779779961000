import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: 20px 0;
        overflow-y: auto;
        flex-wrap: nowrap;
        gap: 32px;
        padding: 30px;
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
