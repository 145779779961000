import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auditorio: []
}

const auditorioSlice = createSlice({
    name: 'audiotorio',
    initialState,
    reducers: {
        setAuditoriosDados: (state, action) => {
            state.auditorio = action.payload
        }
    }
})

export const { setAuditoriosDados } = auditorioSlice.actions;
export const selectAuditorioDados = (state) => state.auditorio.auditorio;
export const selectVideosTotemGaleria = (state) => {
    const totemGaleria = state.auditorio.auditorio.filter((item, i) => {
        if(item.localizacao === 'Totem galeria') return item;
    } )
    return totemGaleria;
}
export const selectVideosTelaCentral = (state) => {
    const telaCentral = state.auditorio.auditorio.filter((item, i) => {
        if(item.localizacao === 'Tela central') return item;
    } )
    return telaCentral;
}

export const selectVideosTelaDireita = (state) => {
    const telaDireita = state.auditorio.auditorio.filter((item, i) => {
        if(item.localizacao === 'Tela direita') return item;
    } )
    return telaDireita;
}

export const selectVideosTelaEsquerda = (state) => {
    const telaEsquerda = state.auditorio.auditorio.filter((item, i) => {
        if(item.localizacao === 'Tela esquerda') return item;
    } )
    return telaEsquerda;
}

export default auditorioSlice.reducer;