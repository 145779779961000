import styled from 'styled-components';

const CommonButton = styled.a`
    cursor: pointer;
    background-color: #FFF;
    font-size:6px;
    padding: 18px 10px;
    text-decoration: none;
    font-family: 'Arial';
    color: #000;
    text-align: center;
`;
export default CommonButton;