import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCVTRef, setCVTTotem, setRefAndOpenModalOrLink } from '../store/creatingValueTogetherSlice';
import { selectVitrinesBones, selectVitrinesCamiseta, 
        selectVitrinesMoletom, selectVitrinesPolos, 
        selectVitrinesTrekkingboot } from '../store/komatsuShopSlice';
import { setCabecote, setMaquinaTipo } from '../store/maquinasSlice';
import { toggle, openMainModal, setCatalogoModalContentAndOpen, setMaquinaContentText } from "./../store/modalSlice";
import { setVideoDataName, setVideoName } from "./../store/videoModalSlice";
import { setAndOpenExternalModal } from './../store/externalModalSlice';
import { setCVTPDFandOpenModal, setMaquinaVideoAndOpen, setVideoAndOpenVideoPresidente } from '../store/videoModalExternoSlice';
import { setClickOrigin, setOpen } from '../store/modalOrcamentoSlice';

const WindowFunctions = ({ children }) => {
    const dispatch = useDispatch();
    const bones = useSelector(selectVitrinesBones);
    const camisetas = useSelector(selectVitrinesCamiseta);
    const moletons = useSelector(selectVitrinesMoletom);
    const polos = useSelector(selectVitrinesPolos);
    const trekking = useSelector(selectVitrinesTrekkingboot);

    useEffect(() => {
        window.abrirmodal = function(nome){
            dispatch(openMainModal(nome))
        }        
        window.getvitrinevalues = function(nome){
            switch(nome){
                case 'bones':
                    return bones;
                case 'camisetas':
                    return camisetas;
                case 'moletons':
                    return moletons;    
                case 'polos':
                    return polos;
                case 'trekking':
                    return trekking;
                default:
                    return bones;
            }
        }
        window.abrirvitrine = function(nome){
            switch(nome){
                case 'bones':
                    window.open(bones[0].link, '_blank')
                    break;
                case 'camisetas':
                    window.open(camisetas[0].link, '_blank')
                    break;
                case 'moletons':
                    window.open(moletons[0].link, '_blank')
                    break;
                case 'polos':
                    window.open(polos[0].link, '_blank')
                    break;
                case 'trekking':
                    window.open(trekking[0].link, '_blank')
                    break;
                default:
                    window.open(bones[0].link, '_blank')
                    break;
            }
        }

        window.selectVideoModalContent = function(nome){
            dispatch(setVideoDataName(nome))
        }
        
        window.selectMaquinaTipo = function(nome){
            dispatch(setMaquinaTipo(nome))
        }
        
        window.selectVideoModalContentAndOpenModal = function(nome, tipo, ref){
            // dispatch(setVideoName(nome, tipo, ref))
            dispatch(setMaquinaVideoAndOpen(nome, tipo, ref))

        }

        window.abrirModalTexto = function(ref="d61ex", dado="1"){
            dispatch(setMaquinaContentText(ref, dado))
        }

        window.selectCatalogoModalContentAndOpenModal = function(nome="modal_catalogos_maquinas", tipo, ref){
            dispatch(setCatalogoModalContentAndOpen(nome, tipo, ref))
        }

        window.abrirModalCreatingValueTogether = function(ref){
            const pdfsRefs = ['komtrax3', 'simuladores2', 'maxifleet3', 'pmp1', 'pmp2'];
            if(!pdfsRefs.includes(ref)){  
                dispatch(setRefAndOpenModalOrLink(ref))
            } 
        }

        window.toggleModalTeste = function(){
            dispatch(toggle())
        }
        
        window.abrirModalAluguelSeminovos = function(){
            dispatch(openMainModal('modal_aluguel_novos_seminovos'))
            dispatch(setCVTTotem('Aluguel e Usados | Totem'))
        }
        
        window.abrirModalCabecotes = function(cabecote){
            dispatch(openMainModal('modal_cabecotes'))
            dispatch(setCabecote(cabecote))
        }

        window.abrirModalPdfExterno = function(pdfUrl, pdfName){
            dispatch(setAndOpenExternalModal(pdfUrl, pdfName))
        }

        window.abrirModalVideoPalavraPresidente = function(){
            dispatch(setVideoAndOpenVideoPresidente())
        }

        window.abrirModalOrcamento = function(clickOrigin){
            dispatch(setClickOrigin(clickOrigin))
            dispatch(openMainModal('modal_orcamento'));
        }

        window.tabOrcamentoVisibilityOn = function(clickOrigin){
            dispatch(setClickOrigin(clickOrigin));
            dispatch(setOpen(true))
        }
        window.tabOrcamentoVisibilityOff = function(){
            dispatch(setOpen(false))
        }

    },[]);

    return children
}

export default WindowFunctions;