import * as Styled from './styles';
export const IconButton = ({ iconComponent, text, link, clickFn }) => {
    return (
        <Styled.Container>
            { link !== '' ? 
                <Styled.Link href={link} target={'_blank'}>
                    {iconComponent}
                    <span>{text}</span>
                    <div>
                        <Styled.LinkIcon fill={"#FFF"} />
                    </div>
                </Styled.Link>
                :
                <Styled.Link onClick={clickFn}>
                    {iconComponent}
                    <span>{text}</span>
                    <div>
                        <Styled.LinkIcon fill={"#FFF"} />
                    </div>
                </Styled.Link>
            }
        </Styled.Container>
    );
};