import { useEffect, useState } from "react";
import * as Styled from "./styles";
import { useSelector } from "react-redux";
import { selectTitenComTresHqsData } from "../../store/komatsuShopSlice";

import ModalBootstrapReuse from "../../components/MainModal/ModalBootstrapReuse";
import { PDFViewer } from "../../components/PDFViewer";

export const ModalCatalogoPDFs = () => {

  const data = useSelector(selectTitenComTresHqsData);

  const [pdf, setPdf] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  const [toggle, setToggle] = useState(false);

  const openModal = (arquivo, nomeArquivo, referencia) => {
    setPdf(`https://dashboard.komatsushowroom.com.br/img/arquivo/${arquivo}`);
    setPdfName(nomeArquivo);
    setToggle(true);
    window.registerCount(`pdf_${referencia}`);
  }

  return (
    <Styled.Container>
      {data
        ? data.map(
            (
              {
                arquivo,
                nomeArquivo,
                referencia,
                thumbnail,
                tipo,
                titulo,
                totem,
              },
              i
            ) => (
              <Styled.PDFButton key={i} rounded onClick={ () => openModal(arquivo, nomeArquivo, referencia) }>
                <img src={`https://dashboard.komatsushowroom.com.br/img/thumbnail/${thumbnail}`} />
                <Styled.Legend>{nomeArquivo}</Styled.Legend>
              </Styled.PDFButton>
            )
          )
        : null}
        <ModalBootstrapReuse show={toggle} onHideFn={() => setToggle(false)} >
          {pdf ? <PDFViewer pdf={pdf} fileName={pdfName}/> : null }
        </ModalBootstrapReuse>
    </Styled.Container>
  );
};
