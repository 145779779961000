import { ContactContent } from '../../components/ContactContent';
import * as Styled from './styles';

const fakeObj = {
    empresa:'MANTOMAC',
    nome: 'Gabriel Floss',
    tel: '(49) 98846-0131',
    email: 'gabriel@mantomac.com.br'
};

export const ModalRent = () => {
    return (
        <Styled.Container>
            <h4>Reduza seu risco de capital com aluguel de máquinas novas e seminovas Komatsu. Entre em contato com nossos distribuidores:</h4>
            <Styled.Content>
                {/* {Array(4).fill(fakeObj).map((item, i) => <ContactContent key={i} {...item} /> )} */}
            </Styled.Content>
        </Styled.Container>
    );
};