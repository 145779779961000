export const theme = {
    mainColor: "#140A9A", 
    secondaryColor: "#253FC8",
    terciaryColor: '#1B232A',
    quaternaryColor: '#FFC82F',
    primaryGray: '#A5ABAF',
    secondaryGray: '#D1D4D3',
    mainFont: 'Noto Sans',
    modal: {
        background: "#140A9A",
        headings:{
            color:"#FFF"
        }
    },
    mapaBrasil: {
        fill:'#535152',
        hover: '#073399',
        legends: {
            concessionarias:'#F15E28',
            postos: '#039EEE',
            lojas: '#29A650',
        }
    }
}