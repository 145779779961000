import { FaLink } from "react-icons/fa";
import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        overflow-y: auto;
        padding-left: 15px;
        align-items: center;
        color: #919191;
        font-weight: bold;
        font-size: 22px;
        /* flex-grow: 1; */
        outline: none;
        box-shadow: 0px 0px 20px -5px #707070;
        border-radius: 10px;
        width: 380px;
        max-width: 90%;
    `}
`;

export const Link = styled.a`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        border-radius: 10px;
        cursor: pointer;
        outline: none;
        font-family: ${({ theme }) => theme.mainFont};
        :hover {
            text-decoration: none;
        }
        :visited: {
            color: inherit;
        }
        span {
            font-size: 16px;
            color: #919191;
            margin: 0 15px;
        }
        div {
            background-color: ${({ theme }) => theme.mainColor};
            border-radius: 10px;
            padding: 22px;
        }
    `}
`;
export const LinkIcon = styled(FaLink)`
    ${({ theme }) => css``}
`;