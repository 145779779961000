import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        height: 80vh;
        .rpv-core__viewer.rpv-core__viewer--light {
            height: 70vh !important;
            @media screen and (max-width: 550px){
                height: 60vh !important;
            }
        }
    `}
`;