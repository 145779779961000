import { useState } from 'react';
import P from 'prop-types';
import * as Styled from './styles';
import { Form }  from './Form';
import { useSelector } from 'react-redux';
import { selectOrcamentoContent } from '../../store/modalOrcamentoSlice';
export const ModalOrcamento = () => {

    const [submitComplete, setSubmitComplete] = useState(false)
    const content = useSelector(selectOrcamentoContent);
    
    return (
        <Styled.Container>
            {submitComplete ? 
                <>
                    <Styled.Heading>{content.title_confirmation}</Styled.Heading>
                    <Styled.Paragraph>{content.confirmation_text}</Styled.Paragraph>
                </>
                :
                <>
                    <Styled.Heading>{content.title}</Styled.Heading>
                    <Styled.Paragraph>{content.text}</Styled.Paragraph>
                    <Form setCompletFn={() => setSubmitComplete(true)}/>
                </>
            }
        </Styled.Container>
    );
};