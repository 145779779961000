import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
    ${({ theme, modaldialogwidth }) => css`
        z-index: 15000;    
        .modal-dialog {
            max-width: ${modaldialogwidth};
        }
        .modal-content {
            border-radius: 24px;
        }
    `}
`;

export const Header = styled(Modal.Header)`
    ${({ theme, modaldialogwidth }) => css`
        display: flex;
        justify-content: flex-end;
        background-color: ${theme.mainColor };
        border-radius: 24px 24px 0 0; 
        color: #FFF;
    `}
`;

export const IframeWrapper = styled.div`
    ${({ theme }) => css`
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
    `}
`
export const Iframe = styled.iframe`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0px solid transparent;
        border-radius: 0 0 24px 24px;
    `}
`