import { useSelector, useDispatch } from 'react-redux';
import { selectVideosTotemGaleria } from '../../store/auditorioSlice';
import { setUrlAndOpen } from '../../store/videoModalExternoSlice';

import * as Styled from './styles';
import { replaceAllSpecialCharacters } from '../../services/utils';
import { ThumbnailButton } from '../../components/ThumbnailButton';
export const ModalTotemAuditorioVideos = () => {
    const dispatch = useDispatch();
    const videos = useSelector(selectVideosTotemGaleria);
    const setVideo = (index) => {
        dispatch(setUrlAndOpen(videos[index].link))
    }

    return (
        <Styled.Container>
            {videos.reverse().map((video, i) => (
                <Styled.VideoBlock key={i}>
                    <ThumbnailButton onClickFn={() => setVideo(i)} videoUrl={video.link} data={video} />
                    {video.titulo ? 
                    <Styled.Legenda>
                    {replaceAllSpecialCharacters(video.titulo)}
                    </Styled.Legenda>
                    : 
                    null
                    }
                </Styled.VideoBlock>
            ))}
        </Styled.Container>
    );
};