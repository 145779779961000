import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './styles';
import { selectVideoDataSelected } from '../../store/geralSelectors';
import Video from '../../components/Video/Video';
import { splitYoutubeVideoId } from '../../services/videoFunctions';
import { VideosContainer } from '../../components/VideosContainer';
import ModalBootstrapReuse from '../../components/MainModal/ModalBootstrapReuse';
import { Iframe } from '../../components/Iframe';
import { ThumbnailButton } from '../../components/ThumbnailButton';
import { replaceAllSpecialCharacters } from '../../services/utils';

export const ModalVideos = () => {
    const videos = useSelector(selectVideoDataSelected);
    const [videoIndex, setVideoIndex] = useState(0);
    const [openClose, setOpenClose] = useState(false);

    const setVideo = (index) => {
        setOpenClose(true);
        setVideoIndex(index)
    }

    useEffect(() => {
        if(videos.length === 1 ){
         window.pauseSound();
         if(videos[0].referencia != ""){
             window.registerCount('video_'+videos[0].referencia);
         }
        return () => {
                window.resumeSound();
            }
        } 
     },[]);

    return (
        <Styled.Container>
            { videos.length === 1 ? <Iframe video={videos[0].link} /> : <VideosContainer videos={videos} />}
        </Styled.Container>
    );
};