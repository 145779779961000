import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth: false,
    url: '',
    loadingUrlAuth: false
}

const urlAuthSlice = createSlice({
    name: 'urlAuth',
    initialState,
    reducers: {
        setAuth:(state, action) => {
            state.auth = action.payload
        },
        setUrl:(state, action) => {
            state.url = action.payload
        },
        setLoading:(state, action) => {
            state.loadingUrlAuth = action.payload
        }
    }
});

export const setAuthAndUrl = (url) => (dispatch, getState) => {
    dispatch(setAuth(true))
    dispatch(setUrl(url))
}
export const { setAuth, setUrl, setLoading } = urlAuthSlice.actions;
export const selectUrlAuth = (state) => state.urlAuth.auth;
export const selectUrlLink = (state) => state.urlAuth.url;
export const selectUrlLoading = (state) => state.urlAuth.loadingUrlAuth;
export default urlAuthSlice.reducer;