import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from 'react-redux';
import { GlobalStyle } from "./style"

import { theme } from './theme/main.theme';
import { store } from './store/store';
import { ThemeProvider } from "styled-components";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <GlobalStyle />
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

