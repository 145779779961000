import styled from "styled-components"

const NavMenuComponent = styled.ul`
  flex: 1 1 400px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
  max-height: ${({ isOpen }) => (isOpen ? "0px" : "200px")};
  opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};

  transform: ${({ isOpen }) => (isOpen ? "translateY(10%)" : "translateY(0)")};

  list-style: none;
  transition: max-height 0.3s, transform 0.6s;
  animation-delay: 0.3s;
  overflow: hidden;
  overflow-y: auto;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 42px;

    border: 1px solid var(--unnamed-color-a5abaf);
    background: #d1d4d342 0% 0% no-repeat padding-box;
    border: 1px solid #a5abaf;
    border-radius: 5px;

    font: normal normal normal 12px/17px Noto Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 5px 4px #1b232a66;

    cursor: pointer;
    backdrop-filter: blur(7px);
    text-align: center;
    padding: 0 10px;
  }
`
export const NavMenu = ({ buttonsFunctionList, menuIsOpen }) => {
  return (
    <NavMenuComponent isOpen={menuIsOpen}>
      {buttonsFunctionList.map((currentButton, index) => (
        <li key={index} onClick={currentButton.func}>{currentButton.name}</li>
      ))}
    </NavMenuComponent>
  )
}
