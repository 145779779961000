import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        box-shadow: unset;
        justify-content: space-between;
        border-radius: 10px;
        font-family: Noto Sans;
        h5 {
            margin: 0;
        }
        p {
            
        }
    `}
`;