import { useEffect, useState } from "react";
import { PDFViewer } from '../PDFViewer';
import ModalBootstrapReuse from "../../components/MainModal/ModalBootstrapReuse";
import * as Styled from './styles';

export const PDFCatalog = ({ data }) => {
    
    const [pdf, setPdf] = useState(null);
    const [pdfName, setPdfName] = useState(null);
    const [toggle, setToggle] = useState(false);
    
    const openModal = (arquivo, nomeCatalogo, referencia) => {
        setPdf(`https://dashboard.komatsushowroom.com.br/img/arquivo/${arquivo}`);
        setPdfName(nomeCatalogo);
        setToggle(true);
        window.registerCount(`pdf_${referencia}`);
      }

    return (
        <Styled.Container>
            {data.map(({
                arquivo,
                nomeCatalogo,
                referencia,
                thumbnail,
                tipo,
                titulo,
                totem,
            }, i) => (
                 <Styled.PDFButton key={i} rounded onClick={() => openModal(arquivo, nomeCatalogo, referencia)}>
                        <img src={`https://dashboard.komatsushowroom.com.br/img/thumbnail/${thumbnail}`} />
                </Styled.PDFButton>
                )
            )}        
            <ModalBootstrapReuse show={toggle} onHideFn={() => setToggle(false)} >
                {pdf ? <PDFViewer pdf={pdf} fileName={pdfName}/> : null }
            </ModalBootstrapReuse>
        </Styled.Container>
    );
};