import styled, { css } from 'styled-components';

export const Container = styled.li`
    ${({ theme }) => css`
    display: flex;
    flex-grow: 1;
    flex-basis: 40%;
    min-height: 100px;
    justify-content: space-between; 
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #FFF;
    padding: 6px;
    border-radius: 8px;
    gap: 5px;
    background-color: ${({ theme }) => theme.mainColor };
    h6, p {
        font-family: ${({ theme }) => theme.mainFont};
        color: #FFF;
        margin:0;
    }
    h6 {
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    p {
        font-size: 10px;
    }
    /* @media screen and (max-width:768px){
        flex-basis: 100%;
    } */
    @media screen and (max-width: 550px){
        flex-basis: 100%;
        h6 {
            font-size: 8px;
            font-weight: bold;
            margin-bottom: 8px;
        }
        p {
            font-size: 6px;
        }   
    }
    
    
    `}
`;

const Block = styled.div`
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    gap: 5px 0;
    min-width: 140px;
    a {
        color: #FFF;
        span {
            display: block;
        }
    }
    @media (max-width:768px){
        flex-basis: 48%;
        min-width: 50px;
        max-width: 60px;
        a { 
            span {
                display: none;
            }
        }
    }
`