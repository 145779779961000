import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    padding: 40px;
    gap: 20px;
    min-height: 100px;
    `}
`;

export const VideoBlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: none;
        align-items: center;
        width: 326px;
        
        span {
            font-family: ${ theme.mainFont }; 
            color: #FFF;
            text-transform: uppercase;
        }
        svg {
            padding: 40px 0;
        }
    `}
`;