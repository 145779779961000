import { TextContainer } from '../../components/TextContainer';
import { useTheme } from 'styled-components';
import { IconButton } from '../../components/IconButton';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { whatsappHrefString } from '../../services/utils';
import * as Styled from './styles';
export const ModalTextoContatos = () => {
    const theme = useTheme();
    const mock = { 
        title: "Titulo da materia", 
        body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quae ipsum dicta officia ipsa, eligendi quia, nisi magnam animi rem voluptatibus sunt voluptate, enim odit in ullam autem. Delectus, nostrum!" 
    };

    const mockContact = {
        number: '+55 (00) 00000-0000'
    }

    return (
        <Styled.Container>
            <Styled.Texts>
                {Array(5).fill(mock).map((conteudo, i) => <TextContainer key={i} {...conteudo} />)}
            </Styled.Texts>
            <Styled.Whatsapps>
                {Array(5).fill(mockContact).map((contato, i) => <IconButton key={i} text={contato.number} iconComponent={<AiOutlineWhatsApp fill={theme.mainColor} />} link={whatsappHrefString(contato.number)} />)}
            </Styled.Whatsapps>
        </Styled.Container>
    );
};