import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 7vh 5vw;
        justify-content: center;
        overflow-y: auto;

    `}
`;
export const Heading = styled.h2`
    ${({ theme }) => css`
        font-size:  22px;
    `}
`;

export const Paragraph = styled.p`
    ${({ theme }) => css`
        font-size: 16px;
    `}
`;