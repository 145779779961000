import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    overflow-y: auto;
    display: flex;
    padding: 38px;
    flex-wrap: nowrap;
    gap: 0 38px;
    height: 100%;
    @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;

export const TextContainer = styled.div`
    ${({ theme }) => css`
        flex-basis: 100%;
        box-shadow: unset;
        flex-direction: column;
        justify-content: flex-start;
        font-family: ${({ theme }) =>  theme.mainFont};
        h5 {
        font-size: 1.25em;
        margin: 0;
    }
        @media (max-width: 768px){
            flex-basis: 100%;
        }
    `}
`

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        box-shadow: unset;
        flex-direction: column;
        gap: 12px;
        img {
            width: 100%;

        }
    `}
`