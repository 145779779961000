import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contato_via_whatsapp: [],
    loja_virtual: [],
    promocao_do_mes: [],
    totem_com_3_hqs:[],
    vitrines_acessorios: [],
    vitrines_bones: [],
    vitrines_camiseta: [],
    vitrines_moletom: [],
    vitrines_polos: [],
    vitrines_trekkingboot: [],
}

const komatsuShopSlice = createSlice({
    name: 'komatsu_shop',
    initialState,
    reducers: {
        setAllKomatsuShopData:(state, action) => {
            state = action.payload;
        },
        setContatoViaWhatsappData: (state, action) => {
            state.contato_via_whatsapp = action.payload;
        },
        setLojaVirtualData:(state, action) => {
            state.loja_virtual = action.payload;
        },
        setPromocaoMesData:(state, action) => {
            state.promocao_do_mes = action.payload;
        },
        setTitenComTresHqsData:(state, action) => {
            state.totem_com_3_hqs = action.payload;
        },
        setVitrinesDataAcessorios:(state, action) => {
            state.vitrines_acessorios = action.payload;
        },
        setVitrinesDataBones:(state, action) => {
            state.vitrines_bones = action.payload;
        },
        setVitrinesDataCamiseta:(state, action) => {
            state.vitrines_camiseta = action.payload;
        },
        setVitrinesDataMoletom:(state, action) => {
            state.vitrines_moletom = action.payload;
        },
        setVitrinesDataPolos:(state, action) => {
            state.vitrines_polos = action.payload;
        },
        setVitrinesDataTrekkingboot:(state, action) => {
            state.vitrines_trekkingboot = action.payload;
        }
    }
});

export const { setAllKomatsuShopData, setContatoViaWhatsappData, setLojaVirtualData, setTitenComTresHqsData, 
                setPromocaoMesData, setVitrinesDataAcessorios, setVitrinesDataBones,
                setVitrinesDataCamiseta, setVitrinesDataMoletom, setVitrinesDataPolos, setVitrinesDataTrekkingboot
            } = komatsuShopSlice.actions; 

export const setKomatsuShopData = (data) => (dispatch, getState) => {
    const mockState = {
        contato_via_whatsapp: [],
        loja_virtual: [],
        promocao_do_mes: [],
        totem_com_3_hqs:[],
        vitrines_acessorios: [],
        vitrines_bones: [],
        vitrines_camiseta: [],
        vitrines_moletom: [],
        vitrines_polos: [],
        vitrines_trekkingboot: [],
    }
    data.forEach((item) => {
        switch(item.totem){
            case 'Promoção do mês':
                mockState.promocao_do_mes.push(item);
                break;
            case 'Vitrines trekking-boot':
                mockState.vitrines_trekkingboot.push(item);
                break;
            case 'Vitrines camiseta':
                mockState.vitrines_camiseta.push(item);
                break;
            case 'Vitrines polos':
                mockState.vitrines_polos.push(item);
                break;
            case 'Vitrines moletom':
                mockState.vitrines_moletom.push(item);
                break;
            case 'Loja virtual':
                mockState.loja_virtual.push(item);
                break;
                case 'Contato via WhatsApp':
                mockState.contato_via_whatsapp.push(item);
                break;
            case 'Totem com 3 HQs':
                mockState.totem_com_3_hqs.push(item);
                break;
        }
    });
    
    dispatch(setContatoViaWhatsappData(mockState.contato_via_whatsapp))
    dispatch(setLojaVirtualData(mockState.loja_virtual))
    dispatch(setPromocaoMesData(mockState.promocao_do_mes))
    dispatch(setTitenComTresHqsData(mockState.totem_com_3_hqs))
    dispatch(setVitrinesDataAcessorios(mockState.vitrines_acessorios))
    dispatch(setVitrinesDataBones(mockState.vitrines_bones))
    dispatch(setVitrinesDataCamiseta(mockState.vitrines_camiseta))
    dispatch(setVitrinesDataMoletom(mockState.vitrines_moletom))
    dispatch(setVitrinesDataPolos(mockState.vitrines_polos))
    dispatch(setVitrinesDataTrekkingboot(mockState.vitrines_trekkingboot))
}

export const selectContatoViaWhatsappData = state => state.komatsu_shop.contato_via_whatsapp;
export const selectLojaVirtualData = state => state.komatsu_shop.contato_via_whatsapp;
export const selectPromocaoMesData = state => state.komatsu_shop.promocao_do_mes;
export const selectTitenComTresHqsData = state => state.komatsu_shop.totem_com_3_hqs;
export const selectVitrinesAcessorios = state => state.komatsu_shop.vitrines_acessorios;
export const selectVitrinesBones = state => state.komatsu_shop.vitrines_bones;
export const selectVitrinesCamiseta = state => state.komatsu_shop.vitrines_camiseta;
export const selectVitrinesMoletom = state => state.komatsu_shop.vitrines_moletom;
export const selectVitrinesPolos = state => state.komatsu_shop.vitrines_polos;
export const selectVitrinesTrekkingboot = state => state.komatsu_shop.vitrines_trekkingboot;

export default komatsuShopSlice.reducer;