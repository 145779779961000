import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 6px;
        padding: 42px;
        @media (max-width:768px){
            padding: 10px;
        }    
    `}
`;