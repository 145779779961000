import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        h4 {
            font-size: 27px;
            font-family: Noto Sans;
            text-align: center;
            margin-top: 0px;
            padding: 6px;
        }
    `}
`;


export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 25px;
        @media screen and (max-width: 550px){
            align-items: center;
            flex-direction: column;
        }
        @media screen and (max-width: 768px){
            justify-content: center;
        }  
    `}
`;
