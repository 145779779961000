import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectCVTDataByTotem } from '../../store/creatingValueTogetherSlice';
import * as Styled from './styles';
import { ContactContent } from '../../components/ContactContent';
export const ModalAluguelNovosSeminovos = () => {
    const [data] = useSelector(selectCVTDataByTotem);
    useEffect(() => {
        console.log(data);
    },[data]);
    return (
        <Styled.Container>
            <h4>Reduza seu risco de capital com aluguel de máquinas 
            novas e seminovas Komatsu. Entre em contato 
            com nossos distribuidores:</h4>
            <Styled.List>
                {data ? data?.contatos.map(({ email, empresa, link, nome, numero }, i) => <ContactContent key={i}  email={email} empresa={empresa} nome={nome} tel={numero} /> ) : null  }
            </Styled.List>
        </Styled.Container>
    );
};