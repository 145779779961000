import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    
    `}
`;

export const CarouselBlock = styled.button`
    ${({ theme }) => css`
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        background-color: transparent;
        color: #000;
        padding: 45px 25px 16px 25px;
        border-color: transparent;
        box-shadow: 0px 0px 20px -5px #707070;
        border-radius: 10px;
        min-height:220px;
        h6 {
            font-family: ${theme.mainFont};
        }
    `}
`

export const ImageContainer = styled.img`
    overflow-y: auto;
    padding: 70px 0px;
`