import { useSelector } from 'react-redux';
import { selectPromocaoMesData } from '../../store/komatsuShopSlice';
import * as Styled from './styles';
export const ModalShopFullImage = () => {
    const [data] = useSelector(selectPromocaoMesData);
    return (
        <Styled.Container>
            {data.arquivo ? <img src={`https://dashboard.komatsushowroom.com.br/img/foto/${data.arquivo}`} /> : null }
        </Styled.Container>
    );
};