import P from 'prop-types';
import * as Styled from './styles';
import iconHands from './icon-hands.png';
import { useSelector } from 'react-redux';
import { selectOrcamentoContent } from '../../store/modalOrcamentoSlice';

const TabOrcamento = () => {

    const maquina = useSelector(selectOrcamentoContent);

    const onClick = () => {
        const location = window.GetActualPlace();

        window.abrirModalOrcamento(location);
    }

    return (
        <Styled.Container>
            <Styled.Text>Solicitar atendimento</Styled.Text>
           <Styled.Button onClick={onClick}><img src={iconHands}/></Styled.Button>
        </Styled.Container>
    );
};
TabOrcamento.propTypes = {};
export default TabOrcamento;