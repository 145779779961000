import styled, { ThemeContext } from 'styled-components';
import { HiMail } from 'react-icons/hi';
import { useContext } from 'react';
import CommonButton from './CommonButton';
const Button = styled(CommonButton)`
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: start;
    span {
        color: #000;
        text-transform: uppercase;
    }
    svg{
        margin-right: 5px;
        font-size: 15px;
    }
    
    ${({ full }) => {
        if(full === true) return 'height: 100%;'
    }}
    @media screen (max-width: 550px){
        justify-content: center;
    }
`

const EmailBlock = ({ email, full }) => {
    const theme = useContext(ThemeContext);
    return  <Button href={`mailto:${email}`} full={full}>
                <HiMail fill={theme.mainColor} size={30}/>
                <span>Email</span>
            </Button>
};
export default EmailBlock;