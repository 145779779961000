import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 50px;
        padding: 42px;
    `}
`;

export const Image = styled.img`
    ${({ theme }) => css`
        width: 100%;
    `}
`;