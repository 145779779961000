import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        position: fixed;
        bottom: 136px;
        right: 0px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 60px;
        z-index: 1500;
        `}
    `;
export const Text = styled.span`
    ${({ theme }) => css`
        text-align: center;
        font-size: 9px;
        line-height: initial;
        text-shadow: 2px 2px 4px #000000;
        margin-bottom: 5px;
        `}
    `;
export const Button = styled.button.attrs({ type: 'button'})`
    ${({ theme }) => css`
        border: 1px solid transparent;
        background: rgba(20, 10, 154, 0.7);
        color: #fff;
        border-radius: 10px 0 0 10px;
        padding: 10px 10px;
        cursor: pointer;
        font-family: ${theme.mainFont};
        font-weight: 700;
        text-transform: uppercase;
        width: fit-content;
    `}
`