import { createSlice, createSelector } from "@reduxjs/toolkit";
import { openMainModal } from "./modalSlice";

const initialState = {
    creating_value_together: [],
    selected_ref:'comunicacao2',
    selected_totem: 'Comunicação | Contatos',
    selected_tipo:'Contato'
}

const creatingValueTogetherSlice = createSlice({
    name: 'creating_value_together',
    initialState,
    reducers: {
        setCreatingValueTogetherData:(state, action) => {
            state.creating_value_together = action.payload
        },
        setCVTRef:(state, action) => {
            state.selected_ref = action.payload
        },
        setCVTTotem:(state, action) => {
            state.selected_totem = action.payload
        },
        setCVTTipo: (state, action) => {
            state.selected_tipo = action.payload
        }
    }
});

export const { setCreatingValueTogetherData, setCVTRef, setCVTTotem, setCVTTipo } = creatingValueTogetherSlice.actions;

export const updateDataByRef = (ref) => (dispatch, getState) => {
    const { creating_value_together: { creating_value_together } } = getState();
}

export const selectCreatingValueTogetherData = (state) => state.creating_value_together.creating_value_together;
export const selectCVTRef = (state) => state.creating_value_together.selected_ref;
export const selectCVTTotem = (state) => state.creating_value_together.selected_totem;
export const selectCVTTipo = (state) => state.creating_value_together.selected_tipo;

export const setRefAndOpenModalOrLink = (ref) => (dispatch, getState) => {
    dispatch(setCVTRef(ref));
    const state = getState();
    const item = state.creating_value_together.creating_value_together.find((item) => item.referencia === ref);
    if(item.tipo === 'Link') {
        window.open(item.link, '_blank')
    } else {
        dispatch(openMainModal('modal_cvt'))
    }
}


export const selectCVTDataByRef = createSelector(
    selectCreatingValueTogetherData,
    selectCVTRef,
    (data, ref) =>  data.find((item, i) => item.referencia === ref)    
)
export const selectCVTDataByTotem = createSelector(
    selectCreatingValueTogetherData, 
    selectCVTTotem, 
    (data, totem) => data.filter((item, i) => item.totem === totem )
)
export const selectCVTDataByTipo = createSelector(
    selectCreatingValueTogetherData, 
    selectCVTTipo, 
    (data, tipo) => data.filter((item, i) => item.tipo === tipo)
)

export default creatingValueTogetherSlice.reducer;