import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        width: fit-content;
        overflow-y: unset;
        justify-content: flex-end;
        margin-bottom: 10px;
        font-family: 'Roboto';
        font-weight: bold;
        color: #535152;
        font-size: 12px;
        flex-direction: column;
    `}
`;

export const Legenda = styled.div`
    ${({ theme }) => css`
        font-size: 1.4rem;
        font-family: ${theme.mainFont};
        @media (max-width:768px){
            font-size: 1rem;
        }
    `}
`;
export const Dot = styled.div`
    ${({ theme, color }) => css`
        font-family: ${theme.mainFont};
        border:2px solid #707070;
        font-size: 12px;
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 10px;
        margin-right: 10px;
        background-color: ${ color };
    `}
`;
