import { createSlice, createSelector } from '@reduxjs/toolkit';
import { codareaToString } from '../services/estadosFn';

const initialState =  {
    dados:[],
    estado:'',
    distribuidoresEstado:[],
    scroll: false
} ;

const distribuidoresSlice = createSlice({
    name: 'distribuidores',
    initialState,
    reducers: {
        setDados:(state, action) => {
            state.dados = action.payload;
        },
        setEstado:(state, action) => {
            state.estado = action.payload;
        },
        setDistribuidores:(state, action) => {
            state.distribuidoresEstado = action.payload;
        },
        setScroll:(state, action) => {
            state.scroll = action.payload;
        }
    }
});

export const { setDados, setDistribuidores, setEstado, setScroll } = distribuidoresSlice.actions;

export const setDistribuidoresEstado = (estado) => (dispatch, getState) => {
    dispatch(setEstado(estado));
    const codareatostring = codareaToString(estado);
    const { distribuidores: { dados }} = getState();
    const estadoSelecionado = dados[codareatostring];
    if(estadoSelecionado !== undefined){
        dispatch(setDistribuidores(estadoSelecionado));    
    } else {
        dispatch(setDistribuidores([]));    
    }
}

export const triggerScroll = () => (dispatch, getState) => {
    const { distribuidores: { scroll }} = getState();
    dispatch(setScroll(!scroll));
}

export const selectDados = (state) => state.distribuidores.dados;
export const selectEstado = (state) => state.distribuidores.estado;
export const selectDistribuidores = (state) => state.distribuidores.distribuidoresEstado;
export const selectScroll = (state) => state.distribuidores.scroll;

export const selectDistribuidoresByEstado = createSelector(selectDados,selectEstado,(dados, estado) => {
    return dados.find((item, i) => item.estado === estado);
})

export default distribuidoresSlice.reducer;