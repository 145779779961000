import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 40px 30px 15px 30px;
    flex-wrap:wrap;
    span {
        text-align: left;
        width: 100%;
    }
    `}
`;