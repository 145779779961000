import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mainModal:{
        toggle: false,
        name: ''
    },
    textModal: {
        title:'',
        content: ''
    },
    catalogoModal: [],
    specialSize: "xl"
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggle:(state) => {
            state.mainModal.toggle = !state
        },
        open:(state) => {
            state.mainModal.toggle = true
        },
        close:(state) => {
            state.mainModal.toggle = false
        },
        setModalName:(state, action) => {
            state.mainModal.name = action.payload
        },
        setContentText:(state, action) => {
            state.textModal = action.payload
        },
        setCatalogoModalContent: (state, action) => {
            state.catalogoModal = action.payload
        },
        setSpecialSize:(state, action) => {
            state.specialSize = action.payload
        }
    }
});

export const { toggle, open, close, setModalName, setContentText, setCatalogoModalContent, setSpecialSize } = modalSlice.actions;

export const openMainModal = (name) => (dispatch, getState) => {
    if(name === '') return;
    dispatch(setModalName(name))
    dispatch(open())
} 

export const closeMainModal = () => (dispatch, getState) => {
    dispatch(setModalName(''))
    dispatch(close())
}

export const setMaquinaContentText = (ref, dado) => (dispatch, getState) => {
    const { maquinas: { maquinas }} = getState();
    const item = maquinas.find((maquina) => { 
        if((maquina.referencia === ref) === true){
            return maquina
        }
    });
    if(item !== undefined){
        switch(dado){
            case 1:
                dispatch(setContentText({ title: item.int1_titulo, content:item.int1_texto }))
                break;
            case 2:
                dispatch(setContentText({ title: item.int2_titulo, content:item.int2_texto }))
                break;
            case 3:
                dispatch(setContentText({ title: item.int3_titulo, content:item.int3_texto }))
                break;
            case 4:
                dispatch(setContentText({ title: item.int4_titulo, content:item.int4_texto }))
                break;
            case 5:
                dispatch(setContentText({ title: item.int5_titulo, content:item.int5_texto }))
                break;
            case 6:
                dispatch(setContentText({ title: item.int6_titulo, content:item.int6_texto }))
                break;
            default:
                dispatch(setContentText({ title: item.int1_titulo, content:item.int1_texto }))
                break;
        }
        dispatch(setModalName('modal_texto'));
        dispatch(open());
    }
} 

export const setCatalogoModalContentAndOpen = (nome, tipo, ref) => (dispatch, getState) => {
    const { maquinas: { maquinas }} = getState();
    const item = maquinas.find((maquina) => { 
        if((maquina.referencia === ref) === true){
            return maquina
        }
    });
    if(item !== undefined){
        const catalogos = item.catalogos.filter((catalogo) => catalogo.tipo === tipo)
        dispatch(setModalName(nome));
        dispatch(setCatalogoModalContent(catalogos))
        dispatch(open());
    }
}


export const selectToggle = (state) => state.modal.mainModal.toggle;
export const selectModalName = (state) => state.modal.mainModal.name;
export const selectTextModalContent = (state) => state.modal.textModal;
export const selectCatalogoModalContent = (state) => state.modal.catalogoModal;
export const selectSpecialSize = (state) => state.modal.specialSize;

export default modalSlice.reducer;