import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as Styled from './styles';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer, SpecialZoomLevel, createStore } from "@react-pdf-viewer/core";
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Toolbar } from './Toolbar';

export const PDFViewer = ({ pdf, fileName }) => {
    const viewerRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [scale, setScale] = useState(SpecialZoomLevel.PageFit);
    const [currentPage, setCurrentPage] = useState(null);

    const [numPages, setNumPages] = useState(0);

    const zoomPluginInstance = zoomPlugin();
    const { zoomTo } = zoomPluginInstance;

    
    useEffect(() => {
        // zoomTo((currentPage % 2 === 0) ? 0.5 : 1 )
        // const divs = viewerRef.current.querySelectorAll('.rpv-core__inner-page');
        
        // if(currentPage > 0 && divs[currentPage] !== undefined){
            // const newWidth = divs[currentPage].children[0].offsetWidth;
            // zoomTo(0.5);
        // } 
        // else 
        // if(currentPage === 0){
            //     zoomTo(1);
        // }
    },[currentPage]);

    // useEffect(() => {
    //     const divs = viewerRef.current.querySelectorAll('.rpv-core__inner-page');
    //     checkWidth(divs);
    // },[viewerRef.current]);

    const checkWidth = (array) => {
        array.forEach(element => {
            console.log(element.children[0].offsetWidth);
            setWidth(prev => {
                if(prev < element.children[0].offsetWidth){
                    return element.children[0].offsetWidth;
                }
            });
        });
    }
    
    // useEffect(() =>{
    //     console.log(width)
    // },[width] )

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar:IntialToolbar } = toolbarPluginInstance;

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator:(file) => {
            return `${fileName}`;
        }
    });
    const defaultLayoutPluginInstance = defaultLayoutPlugin( {
        // renderToolbar:Toolbar,
        sidebarTabs: (defaultTabs) => []
    });
    
    const onPageChange = (data) => {
        // console.log(data)
        setCurrentPage(data.currentPage);
    }
    const renderPage = (page) => {
        // console.log(page);
    }
    const onDocumentLoad = (data) => {
        setNumPages(data.doc._pdfInfo.numPages);
        // console.log(data);
        // zoomTo(0.5); 
        // setTimeout(() => {
        //     zoomTo(scale); 
        // }, 150);
        
    }
    const renderLoader = (data) => {
        // console.log(data);
    }
    return (
        <Styled.Container ref={viewerRef}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> 
            <IntialToolbar>
                {(props) => <Toolbar slots={props}/> }
            </IntialToolbar>  
            <Viewer                 
                plugins={[toolbarPluginInstance, getFilePluginInstance, zoomPluginInstance]}     
                onDocumentLoad={onDocumentLoad}
                renderLoader={renderLoader}
                // renderPage={renderPage}
                // onPageChange={onPageChange}
                fileUrl={pdf} 
                defaultScale={scale} />
                {/* SpecialZoomLevel.PageFit */}
               
            </Worker>
        </Styled.Container>
    );
};