
import { Modal, Button} from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import styled from 'styled-components';

const TheModal = styled(Modal)`
    overflow-y: initial !important;
    z-index: 16000;
    .modal-dialog{
        max-width: 1280px;
        .modal-content {
            border-radius: 24px;
        }
    }
`
const Body = styled(Modal.Body)`
    height: 100%;
    max-height: 80vh;
    /* overflow-y:auto; */
`
const Header = styled(Modal.Header)`
    background-color: ${({ theme }) => theme.mainColor };
    border-radius: 24px 24px 0 0; 
    color: #FFF;
`;


const ModalBootstrapReuse = ({ show, onHideFn, children }) => {
    
    return <TheModal 
        show={show}
        fullscreen={'xxl-down'}
        size="lg"
        onHide={onHideFn}
    >
    <Header  onHide={onHideFn} >
        <Modal.Title></Modal.Title>
        <AiOutlineClose size={30} fill={'#FFF'} onClick={onHideFn} style={{ cursor: 'pointer' }}/>
    </Header>
    <Body>
        {children}
    </Body>
  
  </TheModal>
}
export default ModalBootstrapReuse;