import * as Styled from './styles';
import { Legends } from './Legends';
import MapaKomatsu from '../../components/MapaKomatsu/MapaKomatsu';
import { SelectedState } from './SelectedState';

export const ModalDestribuidores = () => {
    return (
        <Styled.Container>
            <Styled.BlockBrasil>
                <Styled.DivBrasil>
                    <h4>Clique no estado e confira abaixo do mapa as informações disponíveis</h4>
                    <MapaKomatsu />
                </Styled.DivBrasil>
                <Legends />
            </Styled.BlockBrasil>
            <SelectedState />
        </Styled.Container>
    );
};