import EmailBlock from '../../../../components/ModalRent/EmailBlock';
import PhoneBlock from '../../../../components/ModalRent/PhoneBlock';
import * as Styled from './styles';
export const DistribuidorLinks = ({ tel, email }) => {
    return (
        <Styled.Container>
            {tel !== '' ? <PhoneBlock tel={tel} /> : null }
            {email !== '' ? <EmailBlock email={email} /> : null }
        </Styled.Container>
    );
};