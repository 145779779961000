import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDownloadMateriais } from '../../store/espacoCentenarioSlice';
import * as Styled from './styles';
import { IconButton } from '../../components/IconButton';
import wwwIcon from './img/www-icont.png';
import ModalBootstrapReuse from '../../components/MainModal/ModalBootstrapReuse';
import { PDFViewer } from '../../components/PDFViewer';
export const ModalLinks = () => {
    const [toggle, setToggle] = useState(false);
    const [links] = useSelector(selectDownloadMateriais);

    const openPDF = (arquivo, nomeArquivo, referencia) => {
        setToggle(true);
        console.log('Open PDF');
        // window.OpenPDFViewer(arquivo, nomeArquivo, referencia);
    }

    return <Styled.Container>
            {links ?
            <>
                <Styled.Block onClick={() => openPDF(links.arquivo, links.nomeArquivo, links.referencia )} >
                    <h5>{links.nomeArquivo}</h5>
                    <IconButton iconComponent={<Styled.IconPdf />} text={links.nomeBotaoPDF}/>
                </Styled.Block> 
                <ModalBootstrapReuse show={toggle} onHideFn={() => setToggle(false)}>
                    <PDFViewer special={links.referencia === 'centenariolinkpdf1' ? true : false }  pdf={`https://dashboard.komatsushowroom.com.br/img/arquivo/${links.arquivo}`} fileName={links.nomeArquivo}/>
                </ModalBootstrapReuse>
            </> 
                : null }
            {links ? 
                <Styled.Block >
                    <h5>{links.nomeLink}</h5>
                    <IconButton iconComponent={<Styled.IconWorld src={wwwIcon} />} link={links.link} text={links.nomeBotaoLink} />
                </Styled.Block>
                : null }
        </Styled.Container>
};