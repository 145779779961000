import * as Styled from './styles';
import Interweave from 'interweave';

export const TextContent = ({ arquivo = "", titulo, texto }) => {
    return (
        <Styled.Container>
            <Styled.TextContainer>
                <Interweave content={`<h5>${titulo}</h5><p>${texto}</p>`} />
            </Styled.TextContainer>
            {arquivo !== '' ? <Styled.ImageContainer>
                 <img src={`https://dashboard.komatsushowroom.com.br/img/foto/${arquivo}`} />
                </Styled.ImageContainer> : null }
        </Styled.Container>
    );
};