import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    overflow-y: auto;
    gap: 6px;
    padding: 42px;
    @media (max-width:768px){
        padding: 10px;
    }
    `}
`;

export const Block = styled.div`
    ${({ theme }) => css`
        display: flex;
        background-image: linear-gradient(to bottom, rgba(0,0,0,.3), #493fa712);
        width: 100%;
        height: 68px;
        border-radius: 6px;
        > svg {
            margin: 14px 14px;
            @media (max-width:550px){
                width: 100%;
            }
            @media (max-width:768px){
            }
        }
        @media (max-width:550px){
            height: unset;
            justify-content: center;
            flex-wrap:wrap;
        }
    `}
`;