import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 0px 20px -5px #707070;
        background-color: ${theme.mainColor};
        padding: 5px;
        gap: 5px;
        border-radius: 10px;
        flex-basis: 48%;        
        h4 {
            font-size: 27px;
            font-family: Noto Sans;
            text-align: center;
            margin-top: 0px;
            padding: 6px;
        }
        @media screen and (max-width: 768px){
            /* justify-content: center; */
            flex-basis: 100%;
            width: 100%;
        }
    `}
`;

export const IconsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 5px 0;
        flex-basis: 38%;
        min-width: 38%;
    `}
    
`;
export const TextContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        color: #FFF;
        flex-grow: 1;
        padding: 10px;
        h6 {
            font-family: 'Arial';
            font-weight: bold;
            font-size: 22px;
            margin: 0;
            margin-bottom: 10px;
        }
        span {
            word-break: break-all;
        }
        @media screen and (max-width: 768px){
            h6 {
                font-size: 18px;               
            }
            span {
                font-size: 13px;
            }
        }
    `
    }
`

export const PhoneButton = styled.a`
    ${({ theme }) => css`
        background-color: #FFF;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: start;
        color: #FFF;
        flex-grow: 1;
        text-align: center;
        cursor: pointer;
        padding: 18px 10px;
        font-family: 'Arial';
        span {
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
        }
        svg{
            margin-right: 5px;
            font-size: 15px;
        }
        :hover {
            text-decoration: none;
        }
        @media screen and (max-width: 768px){
            justify-content: center;
            span {
                font-size: 12px;
            }
            svg{
                max-width: 20px;
            }
        }
        ${({ full }) => {
            if(full === true) return 'height: 100%;'
            }
        }
    `}

`
export const EmailButton = styled(PhoneButton)`
    ${({ theme }) => css``}
`