import { useEffect, useState } from "react";
import {
  getVimeoVideoThumbnail,
  checkVideoSource,
  splitVimeoVideoId,
  splitYoutubeVideoId,
} from "../../services/videoFunctions";
import * as Styled from "./styles";
export const ThumbnailButton = ({ onClickFn, videoUrl, data }) => {

    const [src, setSrc] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        switch (checkVideoSource(videoUrl)) {
            case "youtube":
                setId(splitYoutubeVideoId(videoUrl));
                break;
            case "vimeo":
                setId(splitVimeoVideoId(videoUrl));
                break;
            default:
                break;
        }
    }, [videoUrl]);

    useEffect(() => {
        switch (checkVideoSource(videoUrl)) {
            case "youtube":
                setSrc(`https://img.youtube.com/vi/${id}/0.jpg`);
                break;
            case "vimeo":
                // getVimeoVideoThumbnail(id)
                //     .then((res) =>    setSrc(res))
                //     .catch((error) => {
                //         console.warn(error)
                //         setSrc("https://via.placeholder.com/220")
                //     } );

                let videoId = videoUrl.split("/")[3]; 
           
                if(videoId === undefined){
                    videoId = videoUrl.split("/")[1];
                }
                if(videoId === "video"){
                    videoId = videoUrl.split("/")[4];
                }

                fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
                .then(response => response.json())
                .then(jsondata => {
                    setSrc(jsondata[0].thumbnail_large);
                })
                .catch(error =>{
                    // console.log(data);
                    // console.log(error);
                    const FileName = data.thumbnail;
                    const ImagePath = "https://dashboard.komatsushowroom.com.br/img/thumbnail/"
                    if(FileName != undefined){
                        setSrc(ImagePath + FileName);
                    }                    
                });

                break;
            default:
                break;
        }
    }, [videoUrl, id]);

    return (
        <Styled.Container onClick={onClickFn}>
            {src ? <img src={src} /> : null }
        </Styled.Container>
    );
};
