import * as Styled from './styles';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedMaquinasCabecote, selectMaquinasData } from '../../store/maquinasSlice';
import { TextContent } from '../../components/TextContent';
import Interweave from 'interweave';

export const ModalCabecotes = () => {
    const maquinas = useSelector(selectMaquinasData);
    const cabecote = useSelector(selectedMaquinasCabecote)
    
    const [data, setData] = useState(null);

    useEffect(() => {
        if(cabecote !== undefined){
            const item = maquinas.find((maquina) => maquina.referencia === cabecote);
            if(item !== undefined){
                setData(item);
            }
        }
    },[maquinas, cabecote]);

    return (   
        <Styled.Container>
            { data ? 
            <>
                <Styled.ImageContainer>
                    <img src={`https://dashboard.komatsushowroom.com.br/img/foto/${data.cabecote}`} />
                </Styled.ImageContainer> 
                <Styled.TextContainer>
                    <Interweave content={`<h5>${data.titulo}</h5><p>${data.textoCabecote}</p>`} />
                </Styled.TextContainer>
            </>
            : null 
            }
        </Styled.Container>
    );
};