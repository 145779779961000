import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    gap: 5px 0;
    min-width: 140px;
    a {
        color: #FFF;
        span {
            display: block;
        }
    }
    @media (max-width:768px){
        flex-basis: 48%;
        min-width: 50px;
        max-width: 60px;
        a { 
            span {
                display: none;
            }
        }
    }
    `}
`;