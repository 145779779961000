import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { selectCatalogoModalContent } from '../../store/modalSlice';
import { PDFCatalog } from '../../components/PDFCatalog';
export const ModalCatalogosMaquinas = () => {
    const data = useSelector(selectCatalogoModalContent);
    
    return (
        <Styled.Container>
            {data.length < 1 ? null : <PDFCatalog data={data} /> }
        </Styled.Container>
    );
};