import * as Styled from './styles';
import { useTheme } from 'styled-components';
import { BsTelephoneFill } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';
import { removeNonNumeric } from '../../services/utils';

export const ContactContent = ({ empresa, nome, tel, email } ) => {
    const theme = useTheme();
    
    return (
        <Styled.Container>
            <Styled.IconsContainer>
                <Styled.PhoneButton href={`tel:+55${removeNonNumeric(tel)}`} full={false} >
                    <BsTelephoneFill fill={theme.mainColor} size={30} /> 
                    <span>Telefone</span>
                </Styled.PhoneButton>
                {email !== '' ? 
                    <Styled.EmailButton href={`mailto:${email}`} full={false} >
                        <HiMail fill={theme.mainColor} size={30}/>
                        <span>Email</span>
                    </Styled.EmailButton>
                
                : null }
            </Styled.IconsContainer>
            <Styled.TextContainer>
                {empresa ? <h6>{empresa}</h6> : null }
                {nome ? <span>{nome}</span> : null }
                {email ? <span>{email}</span> : null }
                {tel ? <span>{tel}</span> : null }
            </Styled.TextContainer>
        </Styled.Container>
    );
};