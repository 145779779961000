import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        /* height: 100%; */
        /* overflow-y: auto; */
        /* max-height: 80vh; */
        display: flex;
        overflow-y: auto;
        padding: 30px 30px;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 12px;
    `}
`;