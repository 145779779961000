import * as Styled from './styles';
export const Legends = () => {
    return (
        <Styled.Container>
        <Styled.Legenda><Styled.Dot color={`#FFC82F`} />Distribuidores</Styled.Legenda>
        <Styled.Legenda><Styled.Dot color={`#FFF`} />Komatsu no Brasil</Styled.Legenda>
        <Styled.Legenda><Styled.Dot color={`#140A9A`} />Komatsu Forest</Styled.Legenda>
        <Styled.Legenda><Styled.Dot color={`#000`} />Aluguel de Novos e Seminovos</Styled.Legenda>
    </Styled.Container>
    );
};